<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "app",
  methods: {
    ...mapActions("types", ["getTypeList"]),
    ...mapActions("system", ["getSystem"]),
  },
  mounted() {
    this.getTypeList();
    this.getSystem();
  },
};
</script>
<style lang="less">
body {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
ul li {
  list-style-type: none;
}
p {
  margin: 0;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
@font-face {
  font-family: "iconfont";
  src: url("../public/css/iconfont/iconfont.ttf?t=1665743169546")
    format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import types from './modules/typeList';
import system from './modules/system';
Vue.use(Vuex);

// 新建并暴露store
export default new Vuex.Store({
    modules: {
        types,
        system,
    },
});
